/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../../styles/IncomingClaimPage.scss';
import { Button, Input, Popover, PopoverBody, PopoverHeader } from 'reactstrap/lib';
import { DropdownOption } from '../new/typings';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { IncomingPageState, OutgoingPageProps, OutgoingPageState } from '../typings';
import { Label, Table } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import { authorizedOptions, statusOptions } from '../../../helpers/utils';
import { calendar } from 'react-icons-kit/feather';
import { compose } from 'redux';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import EmptyState from '../../claims/empty-state/EmptyState';
import HeaderComponent from '../shared/table/HeaderComponent';
import Helmet from 'react-helmet';
import Icon from 'react-icons-kit';
import LoadingPage from '../../loading/LoadingPage';
import OutgoingRow from '../shared/table/OutgoingRow';
import OutgoingSidePanel from '../side-panel/OutgoingSidePanel';
import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';

class OutgoingClaimPage extends Component<
  RouteComponentProps & FirebaseReducer.Reducer & OutgoingPageProps,
  OutgoingPageState
> {
  private static INITIAL_STATE: IncomingPageState = {
    activeClaim: null,
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(OutgoingClaimPage.propKey(columnType, (event.target as any).value));
  }

  constructor(props: any) {
    super(props);
    this.state = {
      ...OutgoingClaimPage.INITIAL_STATE,
      statusFilter: { value: 'STATUS', label: 'ALL' },
      authorizedFilter: { value: 'Both', label: 'Both' },
      partnerFilter: { value: 'Partner', label: 'Destination Member' },
      countryFilter: { value: 'Country', label: 'Country' },
      searchStringVal: '',
      popoverOpen: false,
    };
  }

  public selectClaim(id: string | null) {
    this.setState(OutgoingClaimPage.propKey('activeClaim', id));
  }

  options = statusOptions.map((status: string) => {
    if (status === 'STATUS') {
      return {
        value: status,
        label: 'ALL',
      };
    }
    return {
      value: status,
      label: status,
    };
  });

  authorizedOpts = authorizedOptions.map((status: string) => {
    return {
      value: status,
      label: status,
    };
  });

  setStatusFilter = (value: DropdownOption) => {
    this.setState({ statusFilter: value });
  };

  setAuthorizedFilter = (value: DropdownOption) => {
    this.setState({ authorizedFilter: value });
  };

  setPartnersFilter = (value: DropdownOption) => {
    this.setState({ partnerFilter: value });
  };

  setCountryFilter = (value: DropdownOption) => {
    this.setState({ countryFilter: value });
  };

  onSearchBtnClick = () => {
    typeof this.props.searchChange === 'function' &&
      this.props.searchChange(this.state.searchStringVal || '');
  };

  onResetSearchBtnClick = () => {
    this.setState({ searchStringVal: '' });
    typeof this.props.searchChange === 'function' && this.props.searchChange('');
  };

  render() {
    const { claims, loading, partner, todos, partnerOptions, partners, countryOptions, dateRangeValue, } = this.props;
    const { activeClaim, searchStringVal } = this.state;
    const claim = claims && activeClaim ? { ...claims[activeClaim], id: activeClaim } : null;
    if (loading) {
      return <LoadingPage />;
    }
    
    if (_.isEmpty(claims)) {
      return (
        <EmptyState
          message={
            searchStringVal !== '' || dateRangeValue.search
              ? 'No claims match your search'
              : 'There are currently no claims in the system'
          }
        />
      );
    }

    let claimsList = _.pickBy(claims, (c) => {
      if (this.state.authorizedFilter.value === 'Authorized') {
        return c.insurer.authorized === true;
      } else if (this.state.authorizedFilter.value === 'Unauthorized') {
        return c.insurer.authorized === false;
      }
      return c;
    });

    claimsList = _.pickBy(claimsList, (c) => {
      if (this.state.statusFilter.value !== 'STATUS') {
        return c.status === this.state.statusFilter.value;
      }
      return c;
    });

    claimsList = _.pickBy(claimsList, (c) => {
      if (this.state.partnerFilter.value !== 'Partner') {
        return c.assign.partner === this.state.partnerFilter.value;
      }
      return c;
    });

    claimsList = _.pickBy(claimsList, (c) => {
      if (this.state.countryFilter.value !== 'Country') {
        return partners[c.assign.partner || '']?.country === this.state.countryFilter.value;
      }
      return c;
    });

    return (
      <div className={`incoming-claim-page-container ${activeClaim ? 'open' : 'closed'}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>AGE | Sent Jobs</title>
        </Helmet>
        <div className="page-title-div"> 
        <Label>Sent Jobs</Label>
        </div>
        <div className="search-div">
          <Input
            name="search"
            value={this.state.searchStringVal}
            placeholder="Search Claim"
            className="search-input"
            onChange={(event) => this.setState({ searchStringVal: event.target.value })}
          />
          <Button onClick={this.onSearchBtnClick} className="search-btn">
            Search
          </Button>
          <Button
            outline
            color="secondary"
            onClick={this.onResetSearchBtnClick}
            className="search-btn">
            Reset
          </Button>
          <>
              <Icon id="Popover1" icon={calendar} size={32} />
              <Popover
                placement="bottom"
                isOpen={this.state.popoverOpen}
                target="Popover1"
                toggle={() => this.setState({ popoverOpen: !this.state.popoverOpen })}>
                <PopoverHeader>Select Date Range</PopoverHeader>
                <PopoverBody>
                  <div className="date-form">
                    <div>
                      <label>From: </label>
                      <DatePicker
                        selected={new Date(dateRangeValue.fromDate)}
                        className="date-input"
                        onChange={(value: Date) => {
                          this.props.setFromDateValue(value.toString());
                        }}
                      />
                    </div>
                    <div>
                      <label>To: </label>
                      <DatePicker
                        selected={new Date(dateRangeValue.toDate)}
                        className="date-input"
                        onChange={(value: Date) => {
                          this.props.setToDateValue(value.toString());
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        outline
                        color="secondary"
                        onClick={() => {
                          this.props.searchDateRange(true);
                          this.setState({
                            popoverOpen: !this.state.popoverOpen,
                          });

                        }}
                        className="search-btn">
                        Done
                      </Button>
                      <Button
                        outline
                        color="secondary"
                        onClick={() => {
                          this.props.setFromDateValue(moment('2000-01-01').toDate().toString());
                          this.props.setToDateValue(moment('2100-01-01').toDate().toString());
                          this.props.searchDateRange(false);
                        }}
                        className="search-btn">
                        Reset
                      </Button>
                    </div>
                  </div>
                </PopoverBody>
              </Popover>{' '}
            </>
        </div>
        <Table>
          <HeaderComponent
            statusFilter={this.state.statusFilter}
            setStatusFilter={this.setStatusFilter}
            options={this.options}
            partnerOptions={partnerOptions}
            partnerFilter={this.state.partnerFilter}
            setPartnersFilter={this.setPartnersFilter}
            queue="Destination"
            authorizedOptions={this.authorizedOpts}
            authorizedFilter={this.state.authorizedFilter}
            setAuthorizedFilter={this.setAuthorizedFilter}
            countryOptions={countryOptions}
            countryFilter={this.state.countryFilter}
            setCountryFilter={this.setCountryFilter}
          />
          <tbody>
            {_.map(claimsList, (c, id: string) => (
              <OutgoingRow
                key={`outgoing-row-${id}`}
                claim={c}
                partners={partners} 
                onClick={() => this.selectClaim(id)}/>
            ))}
          </tbody>
        </Table>
        <OutgoingSidePanel
          claim={claim}
          todos={todos}
          partner={partner}
          onClose={() => this.selectClaim(null)}
        />
      </div>
    );
  }
}

const preMapStateToProps = (state: any) => {
  const {
    firebase: { profile },
    searchString,
    dateRangeValue,
  }: { 
    firebase: FirebaseReducer.Reducer;
    searchString: { searchString: string }; 
    dateRangeValue: { toDate: string; fromDate: string; search: boolean };
  } = state;
  return {
    profile: profile,
    searchString,
    dateRangeValue,
  };
};

const mapQueryToProps = ({ 
  profile, 
  searchString, 
  dateRangeValue,
  firestore,
}: { 
  searchString: { searchString: string }, 
  dateRangeValue: { toDate: string; fromDate: string; search: boolean };
  firestore: FirestoreReducer.Reducer;
  profile: any 
}): ReduxFirestoreQueries => {
  if (!profile.isLoaded || profile.isEmpty) {
    return [];
  }
  let fromDate = moment('2000-01-01').toDate();
  let toDate = moment('2100-01-01').toDate();
  if (dateRangeValue.search) {
    fromDate = new Date(dateRangeValue.fromDate);
    toDate = new Date(dateRangeValue.toDate);
  }
  fromDate = firestore.Timestamp.fromDate(fromDate);
  toDate = firestore.Timestamp.fromDate(toDate);
  if (searchString.searchString === '') {
  return [
    {
      collection: 'claims',
      where: [
        ['assign.origin', '==', profile.partnerId],
        ['status', 'in', ['NEW', 'CONTACTED', 'BOOKED', 'EXCESS_COLLECTED','SERVICE DONE', 'ACCEPTED', 'INVOICED', 'CANCELLED']], // ['active', '==', true],
        ['_audit.created.timestamp', '>=', fromDate],
        ['_audit.created.timestamp', '<=', toDate],
      ],
      // limit: pagination.take,
      orderBy: ['_audit.created.timestamp', 'desc'],
    },
    {
      collection: 'config',
      doc: 'todos',
      storeAs: 'todosConfig',
    },
    {
      collection: 'partners',
      doc: profile.partnerId,
      storeAs: 'partner',
    },
    {
      collection: 'partners',
      storeAs: 'partners',
    },
  ];
} else {
  return [
    {
      collection: 'claims',
      where: [
        ['assign.partners', 'array-contains', profile.partnerId],
        ['active', '==', true],
        ['insurer.claimNumber', '==', searchString.searchString],
      ],
      orderBy: ['_audit.created.timestamp', 'desc'],
    },
    {
      collection: 'claims',
      where: [
        ['assign.partners', 'array-contains', profile.partnerId],
        ['active', '==', true],
        ['vehicle.registrationNumber', '==', searchString.searchString],
      ],
      orderBy: ['_audit.created.timestamp', 'desc'],
    },
    {
      collection: 'config',
      doc: 'todos',
      storeAs: 'todosConfig',
    },
    {
      collection: 'partners',
      doc: profile.partnerId,
      storeAs: 'partner',
    },
    {
      collection: 'partners',
      storeAs: 'partners',
    },
  ];
}
};

const mapDispatchToProps = (dispatch: any) => ({
  searchChange: (searchVal: string) => {
    dispatch({
      type: 'SEARCH_CHANGE',
      value: searchVal,
    });
  },
  setToDateValue: (toDateValue: string) => {
    dispatch({
      type: 'SET_TO_DATE_VALUE',
      value: toDateValue,
    });
  },
  setFromDateValue: (fromDateValue: string) => {
    dispatch({
      type: 'SET_FROM_DATE_VALUE',
      value: fromDateValue,
    });
  },
  searchDateRange: (value: boolean) => {
    dispatch({
      type: 'SEARCH_DATE_RANGE',
      value: value,
    });
  },
});

const mapStateToProps = (state: any) => {
  const {
    firestore,
    firebase: { auth },
    searchString,
    dateRangeValue,
    pagination,
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
    pagination: { skip: number; take: number };
    searchString: { searchString: string };
    dateRangeValue: { toDate: string; fromDate: string; search: boolean };
  } = state;
  const partner = firestore.data.partner;
  const partners = firestore.data.partners;
  // const partnersList = firestore.data.partners;
  // const partnersData = firestore.data.partners;
  const partnerOptions: Array<{ value: string; label: string }> = [];
  const countryOptions: Array<{ value: string; label: string }> = [];
  _.map(partners, (partner, id) => {
    partnerOptions.push({ value: id, label: partner.name });
    countryOptions.push({ value: partner.country, label: partner.country });
  });
  partnerOptions.push({ value: 'Partner', label: 'Destination Member' });
  countryOptions.push({ value: 'Country', label: 'Country' });
  let claims = firestore.data.claims;
  if (searchString.searchString !== '') {
    if (firestore.data.claims) {
      claims = firestore.data.claims;
    } else if (firestore.data.claimsReg) {
      claims = firestore.data.claimsReg;
    }
  }
  const todosConfig = firestore.data.todosConfig;
  const loading = !isLoaded(claims) || !isLoaded(todosConfig) || !isLoaded(partner);
  let todos = null;
  if (!loading) {
    const masterTodo = todosConfig.todos;
    const partnerConfig = partner.claimTemplate.todos;
    todos = _.assign({}, masterTodo, partnerConfig);
  }
  return {
    loading,
    claims,
    todos,
    partner,
    partners,
    pagination,
    auth: auth as FirebaseReducer.AuthState,
    // partnersList,
    partnerOptions,
    countryOptions,
    dateRangeValue,
  };
};

export default compose(
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(OutgoingClaimPage);
