/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
import { Badge, Button } from 'reactstrap';
import { QueueRowProps } from '../../typings';
import { check, user, x } from 'react-icons-kit/feather';
import Icon from 'react-icons-kit';
import React from 'react';
import _ from 'lodash';
import moment from 'moment';

const IncomingRow: React.FC<QueueRowProps> = ({ claim, partners, onClick }) => {
  if (!claim) {
    return null;
  }
  const partnersList = partners;
  const authorizedIcon = claim && claim.insurer.authorized ? check : x;
  const authorizedClass = claim && claim.insurer.authorized ? 'text-success' : 'text-danger';
  const updateDate = !_.isDate(claim._audit.updated.timestamp)
    ? moment(claim._audit.updated.timestamp.toDate()).format('DD MMM, YYYY')
    : '-';
  const createDateMoment = !_.isDate(claim._audit.created.timestamp)
    ? moment(claim._audit.created.timestamp.toDate())
    : moment();
  const createDate = createDateMoment.format('DD MMM, YYYY');
  const todayMoment = moment();

  const [data, setData] = React.useState([]);
  let sortedData = [];
  const sortAscending = () => {
    sortedData = data.sort((a, b) => a - b)
    setData(sortedData)
}
const sortDescending = () => {
    sortedData = data.sort((a, b) => b - a)
    setData(sortedData)
}

  const daysOpen = Math.max(todayMoment.diff(createDateMoment, 'days'), 1);
  return (
    <tr>
      <td>
        <Button className="table-inline-button" onClick={(e) => onClick(e, claim)}>
          {claim.insurer.claimNumber}
        </Button>
      </td>
      <td className="text-center">{claim.vehicle.make}</td>
      <td className="text-center">{claim.vehicle.model}</td>
      <td className="text-center">{claim.vehicle.registrationNumber}</td>
      <td className="text-center">
        <Icon icon={authorizedIcon} className={authorizedClass} />
      </td>
      <td className="text-center">{claim.assign.origin}</td>
      <td className="text-center width-7">
        {partnersList[claim?.assign?.origin || '']?.country}
      </td>
      <td className="text-center">{claim.status}</td>
      <td className="text-center">
        <Badge color="secondary" size="lg">
          {daysOpen}
        </Badge>
      </td>
      <td className="text-center">{createDate}</td>
      <td className="text-center">{updateDate}</td>
      <td className="text-center">
        {claim.assign.owners.partner ? (
          <div className="claim-user-icon">
            <Icon icon={user} size={12} />
          </div>
        ) : (
          false
        )}
      </td>
    </tr>
  );
};

export default IncomingRow;
