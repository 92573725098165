/* eslint-disable prettier/prettier */
import '../Ticker/styles.scss';
import * as React from 'react';

interface TickerListProps {
  children: React.ReactNode;
  name?: string;
  slideSpeed?: number;
  visibleItems?: number; // @TODO not implemented
  isNewsTicker?: boolean;
}

const TickerList: React.FunctionComponent<TickerListProps> = (props) => {
  const animation = props.slideSpeed ? `scroll ${props.slideSpeed}s linear infinite` : 'scroll 10m linear infinite';
  return (
    <div className="ticker">
      <div style={{ animation: animation, display: "flex" }}>
        <div className="ticker-list">
          {props.children}
        </div>

        {
          !props.isNewsTicker ? <div className="ticker-list" aria-hidden={true}>
            {props.children}
          </div> : '' 
        }
      </div>
    </div>
  )
}

export default TickerList;
