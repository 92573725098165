/* eslint-disable prettier/prettier */
import { Badge, Button } from 'reactstrap';
import { IClaim, QueueRowProps } from '../../typings';
import { IRatingObject } from '../../../../../../typings';
import { RatingView } from 'react-simple-star-rating';
import { check, download, loader, user, x } from 'react-icons-kit/feather';
import { connect, useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { ratingsReleaseDate } from '../../../../helpers/utils';
import { submitRatingForClaim } from '../../../../actions/update-claim';
import { toast } from 'react-toastify';
import Icon from 'react-icons-kit';
import RateModal from '../../../modals/RateModal';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

const OutgoingRow: React.FC<QueueRowProps> = ({
  claim,
  onClick,
  dowloadLoading,
  downloadProofOfWork,
  claimId,
  showDownloadIcon,
  submitRatingForClaim,
  partner,
  allowRating,
  partners,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [claimIdSelected, setClaimIdSelected] = useState('');
  const [ratingFlow, setRatingFlow] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [ratingsObj, setRatingsObj] = useState<any>({});
  const submitRating = (rating: number, reason = '') => {
    if (!claim) {
      return false;
    }
    setRatingLoading(true);
    if (typeof submitRatingForClaim === 'function') {
      submitRatingForClaim(claimId || '', Number(rating), reason, (data: any, err: Error) => {
        setRatingLoading(false);
        if (err) {
          console.error(err);
          toast.error('Could not rate claim, please try again later.');
          return;
        } else {
          if (data) {
            toast.success('Successfully rated the claim');
          } else {
            toast.error('Could not rate claim, please try again later.');
          }
        }
      });
    }
  };

  const confirmRating = (rating: number, reason = '') => {
    setModalOpen(false);
    submitRating(rating, reason);
  };

  const cancelRating = () => {
    setModalOpen(false);
  };

  const showModal = (claimId: string, claim: IClaim) => {
    setModalOpen(true);
    setClaimIdSelected(claimId);
    if (partner?.replaceAll(' ', '') === claim.assign.origin) {
      setRatingFlow('destination');
      setPartnerName(claim.assign.partner || '');
    } else if (partner?.replaceAll(' ', '') === claim.assign.partner) {
      setRatingFlow('origin');
      setPartnerName(claim.assign.origin || '');
    }
  };

  const partnersList = partners;
  const authorizedIcon = claim && claim.insurer.authorized ? check : x;
  const authorizedClass = claim && claim.insurer.authorized ? 'text-success' : 'text-danger';
  const updateDate = !_.isDate(claim._audit.updated.timestamp)
    ? moment(claim._audit.updated.timestamp.toDate()).format('DD MMM, YYYY')
    : '-';
  const createDateMoment = !_.isDate(claim._audit.created.timestamp)
    ? moment(claim._audit.created.timestamp.toDate())
    : moment();
  const createDate = createDateMoment.format('DD MMM, YYYY');
  const todayMoment = moment();
  const daysOpen = Math.max(todayMoment.diff(createDateMoment, 'days'), 1);
  const isAfterRelease = createDateMoment.isAfter(ratingsReleaseDate);
  const showRating =
    !claim.active &&
    claim.status === 'COMPLETED' &&
    isAfterRelease &&
    allowRating &&
    claim.assign.partner !== claim.assign.origin;
  const hideRating =
    (((!claim.active && claim.status === 'CANCELLED') || !isAfterRelease) && allowRating) ||
    claim.assign.partner === claim.assign.origin;

  let query: any = [];

  if (claimId) {
    query = [
      {
        collection: 'claims',
        doc: claimId,
        subcollections: [
          {
            collection: 'ratings',
            storeAs: 'ratings' + claimId,
          },
        ],
        storeAs: 'ratings' + claimId,
      },
    ];
  }

  useFirestoreConnect(query);

  const ratingObj: IRatingObject = useSelector(({ firestore: { data } }: any) => {
    return data && data[`ratings${claimId}`] ? data[`ratings${claimId}`] : null;
  });

  const ratingObjLoaded = useSelector(({ firestore: { data } }: any) => {
    return isLoaded(data[`ratings${claimId}`]);
  });

  useEffect(() => {
    if (ratingObj && ratingObjLoaded && claimId) {
      const ratingsArray = Object.values(ratingObj)[0];
      let partnerRating = 0;
      if (ratingsArray.destinationPartnerRating.ratedByPartner === partner?.replaceAll(' ', '')) {
        partnerRating = ratingsArray.destinationPartnerRating.rating.toString();
      } else if (ratingsArray.originPartnerRating.ratedByPartner === partner?.replaceAll(' ', '')) {
        partnerRating = ratingsArray.originPartnerRating.rating.toString();
      }
      const newRatingsObj = { ...ratingsObj };
      newRatingsObj[claimId] = partnerRating;
      setRatingsObj(newRatingsObj);
    }
  }, [claimId, ratingObj, ratingObjLoaded]);

  if (!claim) {
    return null;
  }

  return (
    <tr>
      <td>
        <Button className="table-inline-button" onClick={(e) => onClick(e, claim)}>
          {claim.insurer.claimNumber}
        </Button>
      </td>
      <td className="text-center">{claim.vehicle.make}</td>
      <td className="text-center">{claim.vehicle.model}</td>
      <td className="text-center">{claim.vehicle.registrationNumber}</td>
      <td className="text-center">
        <Icon icon={authorizedIcon} className={authorizedClass} />
      </td>
      <td className="text-center">{claim.assign.partner}</td>
      <td className="text-center width-7">
        {partnersList[claim?.assign?.partner || '']?.country}
      </td>
      <td className="text-center">{claim.status}</td>
      <td className="text-center">
        <Badge color="secondary" size="lg">
          {daysOpen}
        </Badge>
      </td>
      <td className="text-center">{createDate}</td>
      <td className="text-center">{updateDate}</td>
      {claimId && showRating && (
        <td className="text-center rate-text">
          {!ratingLoading ? (
            <>
              {ratingsObj[claimId || ''] ? (
                <RatingView fillColor="#ffd700" size={14} ratingValue={ratingsObj[claimId || '']} />
              ) : (
                <span onClick={() => showModal(claimId || '', claim)}>Rate</span>
              )}
            </>
          ) : (
            <Icon icon={loader} className="spin" size={16} />
          )}
        </td>
      )}
      {claimId && hideRating && <td className="text-center rate-text">-</td>}
      {showDownloadIcon && (
        <td className="text-center">
          {!claim.active && claim.status === 'COMPLETED' ? (
            <>
              {!dowloadLoading[claimId || ''] ? (
                <div title="Download Lifetime Guarantee Certificate">
                  <Icon
                    onClick={downloadProofOfWork?.bind(this, claimId || '')}
                    icon={download}
                    size={16}
                  />
                </div>
              ) : (
                <div>
                  <Icon icon={loader} className="spin" size={16} />
                </div>
              )}
            </>
          ) : (
            false
          )}
        </td>
      )}
      <td className="text-center">
        {claim.assign.owners.partner ? (
          <div className="claim-user-icon">
            <Icon icon={user} size={12} />
          </div>
        ) : (
          false
        )}
      </td>
      {modalOpen && (
        <RateModal
          partnerName={partnerName?.replaceAll(' ', '') || ''}
          onConfirm={confirmRating}
          onCancel={cancelRating}
          isOpen={modalOpen}
          claimId={claimIdSelected}
          ratingFlow={ratingFlow}
        />
      )}
    </tr>
  );
};

export default connect(null, { submitRatingForClaim })(OutgoingRow);
