/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../styles/Sidebar.scss';
import { Button } from 'reactstrap';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  WithFirebaseProps,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { IProfile } from '../claims/new/typings';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  arrowDownLeft,
  arrowUpRight,
  checkSquare,
  inbox,
  info,
  power,
  users,
} from 'react-icons-kit/feather';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AsyncCSV from './AsyncCSV';
import Icon, { IconProp } from 'react-icons-kit';
import InfoPanel from '../modals/infopanel/InfoPanel';
import NewsIcon from "../../images/newspaper.svg";
import React, { Component, ReactElement } from 'react';
import _ from 'lodash';
import ageIcon from "../../images/logohome.svg";
import history from '../../helpers/history';
import moment from 'moment';
import plusIcon from "../../images/Plus Icon Red.svg";
const initialState: SideBarState = {
  isOpen: {
    infoPanel: false,
  },
};
class Sidebar extends Component<
  WithFirebaseProps<any> & RouteComponentProps & SidebarProps,
  SideBarState
> {
  constructor(props: any) {
    super(props);
    this.logout = this.logout.bind(this);
    this.state = { ...initialState };
    this.navigateTo = this.navigateTo.bind(this);
    this.toggleInfoPanel = this.toggleInfoPanel.bind(this);
    this.renderMeta = this.renderMeta.bind(this);
  }

  navigateTo(route: string) {
    return () => {
      history.push(route);
    };
  }

  renderMeta(label: string) {
    const metaPages = ["incoming", "outgoing", "completed"];
    if (!_.includes(metaPages, label) || !this.props.ready) {
      return false;
    }
    const key = label as "incoming" | "outgoing" | "completed";
    const counts = this.props[key];
    return (
      <div className="sidebar-btn-meta">
        <div className="meta-text">{counts.count} Claims</div>
        <div className="meta-text">{counts.unclaimed} Unassigned</div>
      </div>
    );
  }

  renderButton(
    icon: IconProp,
    label: string,
    color: string,
    action: Function,
    routes?: string[]
  ): ReactElement {
    const {
      match: { path },
    } = this.props;
    const activeClass = routes && _.includes(routes, path) ? "active" : "";
    return (
      <Button
        className={`sidebar-button ${activeClass}`}
        onClick={() => action()}
      >
        <Icon icon={icon} size={24} className="sidebar-btn-icon" />
        <div className="sidebar-btn-main">
          <div className="sidebar-btn-text">{label}</div>
          {this.renderMeta(label.toLowerCase())}
        </div>
      </Button>
    );
  }

  toggleInfoPanel() {
    const { isOpen } = this.state;
    this.setState({ isOpen: { ...isOpen, infoPanel: !isOpen.infoPanel } });
  }

  logout() {
    this.props.firebase.logout();
  }

  render() {
    const { isOpen } = this.state;

    if (!this.props.profile.partnerId) {
      return <></>;
    }

    return (
      <div>
        <button
          className="logo"
          onClick={
            this.props.profile.partnerId === "Admin"
              ? this.navigateTo("/all")
              : this.navigateTo("/")
          }
        >
          <img src={ageIcon} alt="age icon" />
          <h2>Dashboard</h2>
        </button>
        <div className="sidebar-buttons">
          <div className="spacer"></div>
          <InfoPanel isOpen={isOpen.infoPanel} toggle={this.toggleInfoPanel} />
          {this.props.profile.partnerId === "Admin" ? (
            <>
              {this.renderButton(
                inbox,
                "Global Lifetime Guarantee Code Verification",
                "default",
                this.navigateTo("/all"),
                ["/all"]
              )}
              {this.renderButton(
                users,
                "Users",
                "default",
                this.navigateTo("/users"),
                ["/users"]
              )}
              <AsyncCSV />
            </>
          ) : (
            <>
              {this.renderButton(
                arrowDownLeft,
                "Received",
                "default",
                this.navigateTo("/incoming"),
                ["/incoming"]
              )}
              {this.renderButton(
                arrowUpRight,
                "Sent",
                "default",
                this.navigateTo("/outgoing"),
                ["/outgoing"]
              )}
              {this.renderButton(
                checkSquare,
                "Completed",
                "default",
                this.navigateTo("/completed"),
                ["/completed"]
              )}
              {this.renderButton(
                inbox,
                "Global Lifetime Guarantee Code Verification",
                "default",
                this.navigateTo("/all"),
                ["/all"]
              )}
              <div className="spacer"></div>
              {this.renderButton(
                info,
                "Resources",
                "default",
                this.toggleInfoPanel
              )}
            </>
          )}
        </div>
        <div className="sidebar-bottom">
        {this.props.profile.partnerId === "Admin" ? (
            <Button
              className="new-claim-btn"
              color="new-claim"
              onClick={this.navigateTo("/news-feed")}
            >
              <div className="btn-icon">
                <img
                  src={NewsIcon}
                  alt="newsfeed icon"
                />
              </div>
              <div className="sidebar-btn-text">News Feed</div>
            </Button>
          ) : null}
          {this.props.profile.partnerId &&
          this.props.profile.partnerId !== "Admin" ? (
            <Button
              className="new-claim-btn"
              color="new-claim"
              onClick={this.navigateTo("/new-claim")}
            >
              <div className="btn-icon">
                <img
                  src={plusIcon}
                  alt="plus icon"
                />
              </div>
              <div className="sidebar-btn-text">New Claim</div>
            </Button>
          ) : null}
          <div className="version">
            <div>
              {" "}
              {!this.props.isCompact ? "AGE Dashboard v1.1.3" : "v1.1.3"}
            </div>
            {!this.props.isCompact ? (
              <Button className="btn btn-sm logout-btn" onClick={this.logout}>
                Logout
              </Button>
            ) : (
              <Button className="logout-button-sidebar" onClick={this.logout}>
                <Icon icon={power} />
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const preMapStateToProps = ({
  firebase: { profile },
}: {
  firebase: FirebaseReducer.Reducer;
}) => {
  return {
    profile: profile,
  };
};

const mapQueryToProps = ({
  profile,
  firestore,
}: {
  profile: IProfile;
  firestore: FirestoreReducer.Reducer;
}): ReduxFirestoreQueries => {
  if (!profile || !profile.isLoaded || profile.isEmpty) {
    return [];
  }
  let fromDate = moment('2000-01-01').toDate();  
  let toDate = moment('2100-01-01').toDate();
  fromDate = new Date(fromDate);
  toDate = new Date(toDate);
  fromDate = firestore.Timestamp.fromDate(fromDate);
  toDate = firestore.Timestamp.fromDate(toDate);
  return [
    {
      collection: 'partners',
      doc: profile.partnerId === 'Admin' ? 'AutoglassClinic' : profile.partnerId,
      storeAs: 'partner',
    },
    {
      collection: 'claims',
      where: [
        ['assign.partner', '==', profile.partnerId],
        ['status', 'in', ['NEW', 'CONTACTED', 'BOOKED', 'EXCESS_COLLECTED','SERVICE DONE', 'ACCEPTED', 'INVOICED', 'CANCELLED']], //['active', '==', true],
      ],
      storeAs: 'incomingClaims',
    },
    {
      collection: 'claims',
      where: [
        ['assign.origin', '==', profile.partnerId],
        ['status', 'in', ['NEW', 'CONTACTED', 'BOOKED', 'EXCESS_COLLECTED','SERVICE DONE', 'ACCEPTED', 'INVOICED', 'CANCELLED']], // ['active', '==', true],
      ],
      storeAs: 'outgoingClaims',
    },
    {
      collection: 'claims',
      where: [
        ['assign.partners', 'array-contains', profile.partnerId],
        ['status', '==', 'COMPLETED'],
        ['active', '==', false],
      ],
      storeAs: 'completedClaims',
    },
    {
      collection: 'partners',
      doc: profile.partnerId,
      storeAs: 'partner',
    },
    {
      collection: 'partners',
      storeAs: 'partners',
    },
  ];
};

const mapStateToProps = ({ firestore }: any) => {
  const incomingClaims = _.pickBy(
    firestore.data.incomingClaims,
    (c) => !_.isNull(c)
  );
  const outgoingClaims = _.pickBy(
    firestore.data.outgoingClaims,
    (c) => !_.isNull(c)
  );
  const completedClaims = _.pickBy(
    firestore.data.completedClaims,
    (c) => !_.isNull(c)
  );
  const ready = isLoaded(incomingClaims) && isLoaded(outgoingClaims);
  let unclaimedIncoming = 0;
  let unclaimedOutgoing = 0;
  let unclaimedCompleted = 0;
  if (ready) {
    unclaimedIncoming = _.reduce(
      _.map(incomingClaims, (c) =>
        c?.assign.owners && !_.isString(c?.assign.owners.partner) ? 1 : 0
      ),
      (sum, n) => sum + n,
      0
    );
    unclaimedOutgoing = _.reduce(
      _.map(outgoingClaims, (c) =>
        c?.assign.owners && !_.isString(c?.assign.owners.partner) ? 1 : 0
      ),
      (sum, n) => sum + n,
      0
    );
    unclaimedCompleted = _.reduce(
      _.map(completedClaims, (c) =>
        c?.assign.owners && !_.isString(c?.assign.owners.partner) ? 1 : 0
      ),
      (sum, n) => sum + n,
      0
    );
  }
  return {
    ready,
    incoming: {
      count: _.size(incomingClaims),
      unclaimed: unclaimedIncoming,
    },
    outgoing: {
      count: _.size(outgoingClaims),
      unclaimed: unclaimedOutgoing,
    },
    completed: {
      count: _.size(completedClaims),
      unclaimed: unclaimedCompleted,
    },
  };
};

export default compose<any>(
  withRouter,
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps)
)(Sidebar);
