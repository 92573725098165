/* eslint-disable prettier/prettier */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as fbAdmin from 'firebase-admin';
import * as fns from 'firebase-functions';
import { Button } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { downloadClaimDetailCsvNew } from '../../actions/update-claim';
import React, { Component } from 'react';

export const admin = fbAdmin;
export const functions = fns;
export const firebase = admin.initializeApp();
export const db = admin.firestore();

class AsyncCSV extends Component {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
  }

  // exportMemberDetails = async (filename: string, headers?: string[]) => {
  //   const partnerDetails: any = [];
  //   const partners: any = [];
  //   const partnerDocs = await db.collection("partners").where('active', '==', true).get();
  //   const BASE = db.collection('partners');

  //   const claimDocs = (await BASE.get()).docs;
  //   claimDocs.forEach((doc) => {
  //     const data: any = [doc.data()];
  //     data.push(doc.id);
  //     partners.push(data);
  //   });
  //   return new Promise(async (resolve) => {
  //     for (const index in partnerDocs.docs) {
  //       const partnerDoc = partnerDocs.docs[index];
  //       const partner = partnerDoc.data();
  //       partnerDetails.push({
  //         name: partner.name,
  //         country: partner.country,
  //         keycontact: partner.keycontact,
  //         telephone: partner.telephone,
  //         telephone_alt: partner.telephone_alt,
  //         website: partner.website,
  //         vat: partner.vat,
  //         keyContactEmail: partner.keyContactEmail,
  //         language: partner.language,
  //         currency: partner.currency,
  //         address: partner.address[0],
  //       });
  //     }
  //     if (partnerDetails.length > 0) {
  //       functions.logger.info("Done processing results");
  //       resolve(partnerDetails);
  //     }

  //     resolve(null);
  // });
  // }

  exportToCsv = (filename: string, rows: any, headers?: string[]): void => {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ",";

    const keys: string[] = Object.keys(rows);

    let columHearders: string[];

    if (headers) {
      columHearders = headers;
    } else {
      columHearders = keys;
    }

    const csvContent =
      "sep=,\n" +
      columHearders.join(separator) +
      '\n' +
      rows.map((row: { [x: string]: any; }) => {              
        const cell = row === null || row === undefined ? '' : row;
        return cell;
      }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.setState({ loading: false });
    }
  }

  openLinkInNewTab =  async () => {
    this.setState({ loading: true });
    const respo = await downloadClaimDetailCsvNew();
    // console.log(respo);
    const headerList = ['Claim Id', 'Claim Number', 'Origin Member', 'Destination Member', 'Status', 'Cancelled by', 'Cancelled Reason', 'Calibration Certicate', 'Incident Type',
    'Cause Of Loss', 'Glass Position', 'Created On', 'Ratings Destination Created', 'Ratings Destination Updated', 'Rated By Destination Member', 'Rated By Destination User',
    'Rated On Destination', 'Rating Destination', 'Reason Destination', 'Rated By Origin Member Rating Member', 'Rated By Origin Member Rating User', 'Rated On Origin Member Rating',
    'Rating Origin Member Rating', 'Reason Origin Member Rating'];

    // const headerList1 = ['Member Name', 'Country', 'Key Contact', 'Phone Number', 'Alt Phone Number', 'Website', 'Vat.', 'Contact Email Address', 'Language',
    // 'Currency', 'Address'];

    // this.exportMemberDetails('ClaimDetails.csv', headerList1);

    if (respo !== undefined) {
      this.exportToCsv('ClaimDetails.csv', respo[0], headerList);
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <input
          className="sidebar-btn-text"
          type="button"
          value={loading ? "Downloading..." : "Download Report"}
          onClick={() =>
            //TODO as soon as testing is complete please change the hardcoded URI to variable
            this.openLinkInNewTab()         // "https://us-central1-glassclaims-2e876.cloudfunctions.net/downloadClaimDetailCsv"   
          }
          disabled={loading}
          color="new-claim"
        />
        {/* <a  
          type="button"
          href="https://us-central1-glassclaims-development.cloudfunctions.net/downloadClaimDetailCsv"
          value="facebook"
          target="_blank"
          class="button"
        >
          test
        </a> */}
        {/* <CSVLink
          headers={this.headers}
          data={data}
          filename="ClaimDetails.csv"
          ref={this.csvLinkEl}
        /> */}
      </div>
    );
  }
}

export default AsyncCSV;
