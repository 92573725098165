/* eslint-disable prettier/prettier */
import { Button, Card, CardBody, Collapse, Spinner, Table } from 'reactstrap';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { downloadMemberDetailCsv } from '../../../actions/update-claim';
import BootstrapTable from 'react-bootstrap-table-next';
import Helmet from 'react-helmet';
import LoadingPage from '../../loading/LoadingPage';
import React, { useState } from 'react';
import _ from 'lodash';

const exportToCsv = (filename: string, rows: any, headers?: string[]): void => {
  if (!rows || !rows.length) {
    return;
  }
  const separator = ",";

  const keys: string[] = Object.keys(rows[0]);

  const columnHeaders: string[] = headers ? headers : keys;

  // Adding styling (e.g., making headers uppercase)
  const styledHeaders = columnHeaders.map(header => header.toUpperCase());

  const csvContent =
    "sep=,\n" +
    styledHeaders.join(separator) +
    "\n" +
    rows.map((row: { [x: string]: any; }) => {              
      const cell = row === null || row === undefined ? '' : row;
      return cell;
    }).join('\n');

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const openLinkInNewTab = async (setLoading: (loading: boolean) => void) => {
  setLoading(true);
  const respo = await downloadMemberDetailCsv();
  const headerList = [
    "Member Name", "Address", "Country", "Currency", "Key Contact Email",
    "Key Contact", "Language", "Telephone", "Telephone_alt", "Vat",
    "Watch List EmailIds", "Website"
  ];

  if (respo !== undefined) {
    for (let i = 0; i < respo[0].length; i++) {
      respo[0][i][1] = respo[0][i][1].replace(/,/g, "");
    }
    exportToCsv("MembersList.csv", respo[0], headerList);
  }
  setLoading(false);
};

const PartnersDetails: React.FC<{ partnersData: any[] }> = ({ partnersData }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AGE | Info </title>
      </Helmet>
      <div style={{
        display: 'block',
        paddingLeft: '78%',
        paddingRight: 30,
      }}>
        <Button onClick={() => openLinkInNewTab(setLoading)} disabled={loading}>
          {loading ? <Spinner size="sm" /> : 'Download'}
        </Button>
      </div>
      <br />
      <Table striped bordered hover size="20">
        <thead>
          <tr>
            <th className="text-left">Country</th>
            <th className="text-left">Member Name</th>
            <th className="text-left">Key Contact/Phone Number/Email</th>
            <th className="text-left">Vat./Address</th>
          </tr>
        </thead>
        <tbody>
          {_.map(partnersData, (partner) => (
            partner.active === true ? (
              <tr>
                <td className="text-left">{partner.country}</td>
                <td className="text-left">{partner.name}</td>
                <td className="text-left">
                  <tr style={{ backgroundColor: "transparent" }}>{partner.keycontact}</tr>
                  <tr style={{ backgroundColor: "transparent" }}>{partner.telephone}</tr>
                  <tr style={{ backgroundColor: "transparent" }}>{partner.keyContactEmail}</tr>
                </td>
                <td className="text-left">
                  <tr style={{ backgroundColor: "transparent" }}>{partner.vat}</tr>
                  <tr style={{ backgroundColor: "transparent" }}>{partner.address}</tr>
                </td>
              </tr>)
              : null
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const mapQueryToProps = (): ReduxFirestoreQueries => {
  return [
    {
      collection: 'partners',
      where: ['partnerInformationVisible', '==', true],
      storeAs: 'partners',
    },
  ];
};

const mapStateToProps = (state: any) => {
  const {
    firestore,
    firebase: { auth },
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
  } = state;
  const users = firestore.data.users;
  const partnersData = firestore.data.partners;

  const loading = !isLoaded(partnersData);
  return {
    loading,
    users,
    auth: auth as FirebaseReducer.AuthState,
    partnersData,
  };
};

export default compose(
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps),
)(PartnersDetails);
